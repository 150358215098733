<template>
  <svg
    id="Group_351"
    data-name="Group 351"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="28"
    height="38.601"
    viewBox="0 0 28 38.601"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_105"
          data-name="Rectangle 105"
          width="28"
          height="38.601"
          fill="currentColor"
        />
      </clipPath>
    </defs>
    <g id="Group_350" data-name="Group 350" clip-path="url(#clip-path)">
      <path
        id="Path_114"
        data-name="Path 114"
        d="M76.537,79.686V78.232q0-.341,0-.683c0-.161.04-.3.234-.3s.228.149.228.307c0,.61,0,1.22,0,1.83,0,.1.009.195.012.276.76.069,1.494.115,2.223.208a8.416,8.416,0,0,1,2.9.813,3.394,3.394,0,0,1,.582.4.941.941,0,0,1,.339.769q-.006,10.079,0,20.159a1.091,1.091,0,0,1-.525.929,5.409,5.409,0,0,1-2.087.837,17.07,17.07,0,0,1-7.656-.077,5.212,5.212,0,0,1-1.752-.733,1.2,1.2,0,0,1-.564-1.076q.016-9.929,0-19.858a1.3,1.3,0,0,1,.676-1.21,5.243,5.243,0,0,1,1.956-.73c1.048-.167,2.108-.263,3.163-.388.079-.009.159-.012.271-.02m0,.537c-.753.073-1.466.1-2.159.224a10.853,10.853,0,0,0-1.79.539.615.615,0,0,0-.34.46.679.679,0,0,0,.366.448,4.957,4.957,0,0,0,1.074.383,14.8,14.8,0,0,0,5.642.151,4.176,4.176,0,0,0,1.691-.579c.4-.265.4-.561,0-.832a1.035,1.035,0,0,0-.261-.147,9.316,9.316,0,0,0-1.28-.368c-.8-.127-1.619-.2-2.479-.293a8.309,8.309,0,0,1-.01.883.382.382,0,0,1-.214.279c-.18.036-.24-.116-.239-.283,0-.262,0-.525,0-.864"
        transform="translate(-62.768 -68.806)"
        fill="currentColor"
      />
      <path
        id="Path_115"
        data-name="Path 115"
        d="M2.939,290.986c-.167.153-.346.295-.5.461a1.117,1.117,0,0,0,0,1.659,4.217,4.217,0,0,0,1.473.967,15.625,15.625,0,0,0,3.637,1.036,37.982,37.982,0,0,0,9.361.435,23.706,23.706,0,0,0,6.5-1.209,12.429,12.429,0,0,0,1.7-.847,1.943,1.943,0,0,0,.52-.477,1.077,1.077,0,0,0,.007-1.471c-.167-.212-.339-.419-.444-.548a5.637,5.637,0,0,1,2.46,1.611,1.408,1.408,0,0,1-.035,1.88,4.647,4.647,0,0,1-1.685,1.228,17.033,17.033,0,0,1-4.18,1.3,41.054,41.054,0,0,1-10.057.651,30.74,30.74,0,0,1-8.132-1.348A8.546,8.546,0,0,1,1.184,295.2a4.334,4.334,0,0,1-.884-.84,1.359,1.359,0,0,1,0-1.7,3.532,3.532,0,0,1,1.239-1.052c.444-.243.9-.455,1.358-.68l.042.06"
        transform="translate(-0.001 -259.128)"
        fill="currentColor"
      />
      <path
        id="Path_116"
        data-name="Path 116"
        d="M104.974,0a12.694,12.694,0,0,0,.065,1.3,6.433,6.433,0,0,0,.9,2.21c.41.718.828,1.433,1.218,2.162a3.511,3.511,0,0,1,.286.821,2.079,2.079,0,0,1-.3,1.721A4.721,4.721,0,0,1,105.5,9.6c.485-2.1.252-3.9-1.838-5.1.1.377.184.683.252.994a3.471,3.471,0,0,1-.114,1.778,5.557,5.557,0,0,0-.278,2.177.7.7,0,0,1-.03.134c-.246-.322-.478-.6-.68-.894a5.381,5.381,0,0,1-.972-4.161,5.945,5.945,0,0,1,.9-2.15A10.368,10.368,0,0,1,104.974,0"
        transform="translate(-90.628)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
